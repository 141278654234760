import { Box, Link } from '@chakra-ui/react'

interface SettingsRowIProps {
  label: string
  value: string
  hint?: string
  dataTestId?: string
}

export const SettingsRow = ({
  label,
  value,
  hint,
  dataTestId = 'SettingsRow:value',
}: SettingsRowIProps) => (
  <>
    <Box color='#999a9e' fontWeight='medium' letterSpacing='-0.53px'>
      {label}:
    </Box>
    <Box data-testid={dataTestId} fontWeight='bold' letterSpacing='-0.53px'>
      {value}
      {hint && (
        <Link
          color='#2E6CDB'
          cursor='pointer'
          data-testid={`${dataTestId}_link`}
          fontSize='15px'
          fontWeight='medium'
          href='mailto:support@hivewatch.zendesk.com'
          letterSpacing='-0.53px'
          ml={3}
          textDecoration='underline'
        >
          {hint}
        </Link>
      )}
    </Box>
  </>
)
