import { Circle } from '@chakra-ui/react'

interface CallAvatarIProps {
  title: string
  size: string
  fontSize: string
  colour?: string
  bgColor?: string
  border?: string
}

export const CallAvatar = ({
  title,
  size,
  fontSize,
  border = null,
  colour = 'red',
  bgColor = '#E3EEFA',
}: CallAvatarIProps) => {
  return (
    <Circle
      bgColor={bgColor}
      border={border}
      color={colour}
      fontSize={fontSize}
      fontWeight='bold'
      size={size}
    >
      {title}
    </Circle>
  )
}
