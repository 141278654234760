import { useEffect, useRef, useState } from 'react'

import ReactPlayer from 'react-player'

import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'
import screenfull from 'screenfull'

import { VideoControlsAction } from '../types/types'
import { VideoControls } from './VideoControls'
import { VideoError } from './VideoError'
import { VideoProcessing } from './VideoProcessing'

export interface IProps {
  url: string
  autoplay?: boolean
  controls?: boolean
  pipActive?: boolean
  disablePIP?: () => void
  onToggleLive?: (boolean) => void
  liveEnabled?: boolean
  placeholderWidth?: string
  placeholderHeight?: string
  showToggleLiveButton?: boolean
  errorLabel?: string
  isProcessing?: boolean
  onPlayerControlAction?: (event: VideoControlsAction) => void
  onStartPlaying?: () => void
  liveOnly?: boolean
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  background-color: black;
  border-radius: 0.375rem;
`

let count = 0

export const VideoPlayer = ({
  url,
  pipActive,
  disablePIP,
  onToggleLive,
  placeholderWidth,
  placeholderHeight,
  onPlayerControlAction,
  onStartPlaying,
  errorLabel,
  isProcessing,
  autoplay = true,
  liveEnabled = false,
  showToggleLiveButton = true,
  liveOnly = false,
}: IProps) => {
  const [state, setState] = useState({
    isPlaying: autoplay,
    played: 0,
    seeking: false,
    isPip: false,
  })
  const playerRef = useRef(null)
  const playerContainerRef = useRef(null)
  const controlsRef = useRef(null)
  const { isPlaying, played, isPip } = state

  const handlePlayPause = () => {
    setState({ ...state, isPlaying: !state.isPlaying })
    if (isPlaying) {
      onPlayerControlAction?.(VideoControlsAction.Pause)
    } else {
      onPlayerControlAction?.(VideoControlsAction.Play)
    }
  }

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10)
    onPlayerControlAction?.(VideoControlsAction.Backward10)
  }

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10)
    onPlayerControlAction?.(VideoControlsAction.Forward10)
  }

  const handleProgress = (changeState) => {
    if (controlsRef.current) {
      if (count > 3) {
        controlsRef.current.style.visibility = 'hidden'
        count = 0
      }
      if (controlsRef.current.style.visibility == 'visible') {
        count += 1
      }
    }
    if (!state.seeking) {
      setState({ ...state, ...changeState })
    }
  }

  const handleSeekChange = (newValue) => {
    const value = newValue / 100
    setState({ ...state, played: parseFloat(`${value}`) })
    playerRef.current.seekTo(value, 'fraction')
  }

  const toggleFullScreen = () => {
    if (screenfull.isEnabled) screenfull.toggle(playerContainerRef.current)
    onPlayerControlAction?.(VideoControlsAction.Fullscreen)
  }

  const handleMouseMove = () => {
    if (controlsRef.current) {
      controlsRef.current.style.visibility = 'visible'
      controlsRef.current.style.display = 'flex'
      count = 0
    }
  }

  const hanldeMouseLeave = () => {
    if (controlsRef.current) {
      controlsRef.current.style.visibility = 'hidden'
      controlsRef.current.style.display = 'none'
      count = 0
    }
  }

  const handleEnablePIP = () => {
    setState({ ...state, isPip: true })
  }

  const handleDisablePIP = () => {
    disablePIP()
    setState({ ...state, isPip: false })
  }

  useEffect(() => {
    setState({ ...state, isPip: pipActive })
  }, [pipActive])

  const shouldShowControls = !isProcessing || !!errorLabel
  return (
    <Wrapper
      onMouseLeave={hanldeMouseLeave}
      onMouseMove={handleMouseMove}
      ref={playerContainerRef}
    >
      <Box minH={placeholderHeight} minW={placeholderWidth}>
        {errorLabel && <VideoError errorLabel={errorLabel} />}
        {isProcessing && <VideoProcessing />}
        <ReactPlayer
          data-testid='video-player'
          height='100%'
          loop
          muted
          onDisablePIP={handleDisablePIP}
          onEnablePIP={handleEnablePIP}
          onProgress={handleProgress}
          onStart={onStartPlaying}
          pip={isPip}
          playing={isPlaying}
          ref={playerRef}
          url={url}
          width='100%'
        />
        {shouldShowControls && (
          <VideoControls
            isPlaying={isPlaying}
            liveEnabled={liveEnabled}
            liveOnly={liveOnly}
            onFastForward={handleFastForward}
            onPlayPause={handlePlayPause}
            onRewind={handleRewind}
            onSeek={handleSeekChange}
            onToggleFullScreen={toggleFullScreen}
            onToggleLive={onToggleLive}
            played={played}
            ref={controlsRef}
            showToggleLiveButton={showToggleLiveButton}
          />
        )}
      </Box>
    </Wrapper>
  )
}
