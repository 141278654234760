import { useState } from 'react'
import { ReactNode, createContext, useContext, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { ApolloError, useApolloClient } from '@apollo/client'
import { useSubscription } from '@apollo/client'

import { GraphqlClientType } from '@/config/apollo'
import { ON_CREATE_NOTIFICATION } from '@/graphql/appsync-notifications/subscriptions/onCreateNotification'
import {
  ON_UPDATE_NOTIFICATION_COUNT,
  OnUpdateNotificationCountSubscription,
} from '@/graphql/appsync-notifications/subscriptions/onUpdateNotificationCount'
import { Notification } from '@/graphql/appsync-notifications/types'
import { useAppSelector } from '@/redux/hooks'
import { selectMe } from '@/redux/me/meSlice'
import {
  selectNotifications,
  setNotificationCount,
  setNotifications,
} from '@/redux/notifications/notificationsSlice'

interface NotificationDataIProps {
  notification: Notification
}

interface UseOnNotificationIProps {
  data?: NotificationDataIProps
  error: ApolloError
}

const RECONNECT_INTERVAL = 30000

export const NotificationsContext = createContext<UseOnNotificationIProps>(null)

export const NotificationsProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const dispatch = useDispatch()
  const notifications = useSelector(selectNotifications)
  const me = useAppSelector(selectMe)

  const client = useApolloClient()
  const [shouldReconnect, setShouldReconnect] = useState(true)
  const [data, setData] = useState<NotificationDataIProps | undefined>(
    undefined
  )
  const [error, setError] = useState<ApolloError | undefined>(undefined)

  useEffect(() => {
    if (me?.id) {
      const observer = client.subscribe({
        query: ON_CREATE_NOTIFICATION,
        variables: {
          userId: me?.id,
        },
        context: { target: GraphqlClientType.APPSYNC_NOTIFICATIONS },
      })
      // Recreate new subscription connection
      const subscription = observer.subscribe(
        ({ data }: { data: NotificationDataIProps }) => {
          setData(data)
        },
        (error: ApolloError) => {
          if (error && subscription.closed) {
            // Trigger rerender after interval to recreate a subscription connection
            setTimeout(() => {
              setShouldReconnect((prevShouldReconnect) => !prevShouldReconnect)
            }, RECONNECT_INTERVAL)
          } else {
            setError(error)
          }
        }
      )
      return () => subscription.unsubscribe()
    }
  }, [shouldReconnect, me?.id])

  useEffect(() => {
    if (data?.notification) {
      // Add new notification. Check if unique to prevent dups
      const isFound = notifications.some((n) => {
        if (
          n.notificationTimestamp === data?.notification?.notificationTimestamp
        ) {
          return true
        }
        return false
      })
      if (!isFound) {
        dispatch(setNotifications([...notifications, data?.notification]))
      }
    }
  }, [data])

  useSubscription<OnUpdateNotificationCountSubscription>(
    ON_UPDATE_NOTIFICATION_COUNT,
    {
      variables: { userId: me?.id },
      skip: !me?.id,
      onSubscriptionData: ({ subscriptionData }) => {
        dispatch(
          setNotificationCount(
            subscriptionData?.data?.onUpdateNotificationCount.notificationCount
          )
        )
      },
      context: { target: GraphqlClientType.APPSYNC_NOTIFICATIONS },
    }
  )

  return (
    <NotificationsContext.Provider value={{ data, error }}>
      {children}
    </NotificationsContext.Provider>
  )
}

export const useOnNotification = () => useContext(NotificationsContext)
