import { useRouter } from 'next/router'

import { useIncidentWithDisplayIdQuery } from '@/graphql/generated/hooks'

export const useIncidentWithDisplayId = ({ displayId }) => {
  const { query } = useRouter()
  const { data } = useIncidentWithDisplayIdQuery({
    variables: { displayId },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    skip: query.displayId === undefined,
  })

  const queryIncidentId =
    data?.incidents?.edges?.map((n) => n.node)?.[0]?.id ?? null

  return { queryIncidentId }
}
