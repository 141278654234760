import React from 'react'

import { useCall } from '../hooks/useCall'
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa'
import { IoCallSharp } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Circle, Text, Tooltip } from '@chakra-ui/react'

import {
  openIncidentDrawer,
  selectEmergencyCallIncident,
  selectIsIncidentDrawerOpen,
  setIncident,
} from '@/redux/ui/uiSlice'

import { CallAvatar } from './CallAvatar'

export const CallLive = () => {
  const { isMuted, disconnectCall, toggleMuteCall } = useCall()

  const dispatch = useDispatch()
  const isIncidentDrawerOpen = useSelector(selectIsIncidentDrawerOpen)
  const { id, displayId, facilityName } = useSelector(
    selectEmergencyCallIncident
  )

  const handleIncidentDrawer = () => {
    if (!isIncidentDrawerOpen) {
      dispatch(setIncident({ id }))
      dispatch(openIncidentDrawer())
    }
  }

  return (
    <Box
      alignItems='center'
      display='flex'
      flex={1}
      flexDirection='column'
      mt='50px'
      p='20px'
    >
      <CallAvatar fontSize='28px' size='100px' title='911' />
      <Text color='#282424' fontSize='22px' fontWeight='normal' mt='20px'>
        Live
      </Text>

      <Box
        bgColor='#e2e8f0'
        direction='row'
        display='flex'
        h='1px'
        mb='20px'
        mt='20px'
        w='60%'
      />

      <Text color='#282424' fontSize='18px' fontWeight='normal' mt='20px'>
        {facilityName}
      </Text>

      <Text
        color='#2E6CDB'
        cursor='pointer'
        data-testid='CallLive:DisplayId'
        fontSize='16px'
        fontWeight='normal'
        onClick={handleIncidentDrawer}
      >
        {displayId}
      </Text>

      <Box
        alignItems='center'
        direction='row'
        display='flex'
        justifyContent='center'
        mt='80px'
        w='410px'
      >
        <Tooltip label={isMuted ? 'Unmute' : 'Mute'} placement='top'>
          <Circle
            _hover={{
              bg: '#e2e8f0',
            }}
            as='button'
            backgroundColor={isMuted ? '#969696' : 'white'}
            border='1px solid #e2e8f0'
            data-testid='CallLive:onMute'
            onClick={toggleMuteCall}
            size='80px'
          >
            {isMuted ? (
              <FaMicrophoneSlash color='white' size='30px' />
            ) : (
              <FaMicrophone opacity={0.6} size='30px' />
            )}
          </Circle>
        </Tooltip>
        <Tooltip label='Hangup' placement='top'>
          <Circle
            _hover={{
              opacity: 0.6,
            }}
            as='button'
            backgroundColor='red.500'
            data-testid='CallLive:onDisconnect'
            ml='20px'
            onClick={disconnectCall}
            size='80px'
          >
            <IoCallSharp
              color='white'
              size='40px'
              style={{ transform: 'rotate(135deg)' }}
            />
          </Circle>
        </Tooltip>
      </Box>
    </Box>
  )
}
