/* eslint-disable react/jsx-max-depth */
import { FeatureFlag, useFeatureFlag } from '@/hooks/useFeatureFlags'
import { KeyCloakProvider } from '@/hooks/useKeyCloak/KeyCloakProvider'
import { NotificationsProvider } from '@/hooks/useOnNotification'
import NProgress from 'nextjs-progressbar'
import 'react-datepicker/dist/react-datepicker.css'
import { useStore } from 'react-redux'

import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'
import { AnimateSharedLayout } from 'framer-motion'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'raf/polyfill'
import { PersistGate } from 'redux-persist/integration/react'

import { Head } from '@/components/layouts/Head'
import { useApollo } from '@/config/apollo'
import {
  CallContainer,
  CallFlaggedProvider as CallProvider,
} from '@/features/emergency-call'
import { useAppSelector } from '@/redux/hooks'
import { selectMe } from '@/redux/me/meSlice'
import { wrapper } from '@/redux/store'
import type { AppStore } from '@/redux/store'
import { Fonts } from '@/styles/fonts'
import { GlobalStyles } from '@/styles/global'
import { theme } from '@/styles/theme'
import { useIdentifyUser } from '@/utils/analytics'
import '@/utils/analytics/mixpanel'

import '../../global.css'

const MyApp = wrapper.withRedux(({ Component, pageProps }) => {
  const client = useApollo(pageProps)
  const store: AppStore = useStore()
  const isEmergencyCallEnabled = useFeatureFlag(FeatureFlag.emergencyCall)
  const me = useAppSelector(selectMe)
  useIdentifyUser(me)

  return (
    <PersistGate loading={null} persistor={store.__persistor}>
      <Head />
      <ApolloProvider client={client}>
        <KeyCloakProvider autoRefreshToken>
          <ChakraProvider resetCSS theme={theme}>
            <NotificationsProvider>
              <CallProvider>
                <NProgress
                  color='#2E6CDB'
                  height={4}
                  options={{ showSpinner: false, easing: 'ease', speed: 500 }}
                />
                <Fonts />
                <GlobalStyles />
                <AnimateSharedLayout>
                  <Component {...pageProps} />
                </AnimateSharedLayout>
                {isEmergencyCallEnabled && <CallContainer />}
              </CallProvider>
            </NotificationsProvider>
          </ChakraProvider>
        </KeyCloakProvider>
      </ApolloProvider>
    </PersistGate>
  )
})

// Default export is needed for NextJS /pages/*
// eslint-disable-next-line import/no-default-export
export default MyApp
