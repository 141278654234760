/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo, useEffect, useMemo, useRef, useState } from 'react'

import { useIncidentWithDisplayId } from '../hooks/useIncidentWithDisplayId'
import { useIncidentsFilters } from '@/hooks/useIncidentsFilters'
import { useQueryState } from 'next-usequerystate'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import {
  Column,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import { FixedSizeList, areEqual } from 'react-window'
import { useOnClickOutside } from 'usehooks-ts'

import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Spinner,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  chakra,
} from '@chakra-ui/react'
import memoize from 'memoize-one'

import { Navigation, PageSizeSelector } from '@/components/ui'
import { IncidentStatus } from '@/graphql/generated/schemas'
import {
  openIncidentDrawer,
  selectIncidentsPageSize,
  selectIsIncidentDrawerOpen,
  setIncident,
  setIncidentsPageSize,
} from '@/redux/ui/uiSlice'
import { mixpanel } from '@/utils/analytics'
import { getCurrentRangeMessage } from '@/utils/pageNavigation'

import { RefetchIncidentsFnType } from '../types/types'
import { getOrderBy, getSortedColumn } from '../utils/query'

interface RenderRowIProps {
  data: {
    handleSelectItem: any
    items: {
      getRowProps: any
      cells: any
      original: {
        id: string
        name: string
        displayId: string
        incidentStatus: IncidentStatus
        incidentType: string
      }
    }[]
  }
  index: number
  style: any
}

const createItemData = memoize((items, handleSelectItem) => ({
  items,
  handleSelectItem,
}))

export type DataTableProps<DataT extends unknown> = {
  data: DataT[]
  columns: Column<DataT>[]
  refetch?: RefetchIncidentsFnType
  totalIncidents?: number
}

const ROW_HEIGHT = 40

export const DataTable = <DataT extends unknown>({
  data,
  columns,
  refetch,
  totalIncidents,
}: DataTableProps<DataT>) => {
  const [, setDisplayId] = useQueryState('displayId')
  const { query } = useRouter()
  const ref = useRef(null)
  const { queryIncidentId } = useIncidentWithDisplayId({
    displayId: query?.displayId,
  })
  const dispatch = useDispatch()
  const isIncidentDrawerOpen = useSelector(selectIsIncidentDrawerOpen)
  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 80, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const [selectedId, setSelectedId] = useState(null)

  const { filters } = useIncidentsFilters()
  const initialPageSize = useSelector(selectIncidentsPageSize)
  const skipPageResetRef = useRef(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    getTableProps,
    headerGroups,
    rows,
    columns: tableColumns,
    prepareRow,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      autoResetPage: !skipPageResetRef.current,
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
        sortBy: [
          {
            id: 'date',
            desc: true,
          },
        ],
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      disableSortRemove: true,
      pageCount: -1,
    },
    useFlexLayout,
    useSortBy,
    usePagination
  )
  const pageCount = Math.ceil(totalIncidents / pageSize)
  const sortedColumn = getSortedColumn(tableColumns)

  useEffect(() => {
    skipPageResetRef.current = true
    setIsLoading(true)
    refetch({
      filter: filters,
      first: pageIndex,
      last: pageSize,
      orderBy: getOrderBy(sortedColumn),
    }).then(() => {
      setIsLoading(false)
      skipPageResetRef.current = false
    })
  }, [pageIndex, pageSize, JSON.stringify(sortedColumn)])

  useEffect(() => {
    gotoPage(0)
    skipPageResetRef.current = true
    setIsLoading(true)
    refetch({
      filter: filters,
      first: pageIndex,
      last: pageSize,
      orderBy: getOrderBy(sortedColumn),
    }).then(() => {
      setIsLoading(false)
      skipPageResetRef.current = false
    })
  }, [JSON.stringify(filters)])

  const openAlertIncidentDrawer = (id: string) => {
    dispatch(setIncident({ id }))
    dispatch(openIncidentDrawer())
  }
  const handleSelectItem = (
    id: string,
    displayId: string,
    incidentStatus: IncidentStatus,
    incidentType: string
  ) => {
    setSelectedId(displayId)
    openAlertIncidentDrawer(id)
    setDisplayId(displayId)
    mixpanel.track('Button Press: Incident Row, Incidents View', {
      incident_id: displayId,
      incident_status: incidentStatus,
      incident_type: incidentType,
    })
  }

  const headerClicked = (column) => {
    mixpanel.track('Sorted: Incidents View', {
      sorted_by: column.Header,
    })
  }

  useOnClickOutside(ref, () => {
    if (!isIncidentDrawerOpen && selectedId) {
      setSelectedId(null)
      setDisplayId(null)
    }
  })

  useEffect(() => {
    if (queryIncidentId) {
      setSelectedId(query.displayId)
      openAlertIncidentDrawer(queryIncidentId)
    }
  }, [queryIncidentId])

  const itemData = createItemData(rows, handleSelectItem)

  // eslint-disable-next-line react/no-unstable-nested-components
  const RenderRow = memo<RenderRowIProps>(
    ({ data, index, style }: RenderRowIProps) => {
      const { items, handleSelectItem } = data
      const row = items[index]
      prepareRow(row)
      const isSelected = selectedId === row.original.displayId
      return (
        <Tr
          {...row.getRowProps({
            style,
          })}
          _hover={{
            bgColor: isSelected ? '#4284f8' : 'gray.100',
          }}
          bgColor={isSelected ? '#6096f5' : '#fff'}
          cursor='pointer'
          data-testid='incidentsPage_table_row'
          h='40px'
          onClick={() =>
            handleSelectItem(
              row.original.id,
              row.original.displayId,
              row.original.incidentStatus,
              row.original.incidentType
            )
          }
          transition='ease-in-out'
          transitionDuration='300ms'
          transitionProperty='all'
        >
          {row.cells.map((cell) => (
            <Td
              {...cell.getCellProps()}
              alignItems='center'
              borderColor='#D5DCE4'
              color={isSelected ? '#fff' : '#2D2E41'}
              flexDirection='row'
              fontSize='14px'
              fontWeight='medium'
              isNumeric={cell.column.isNumeric}
              key={cell.id}
              letterSpacing='-0.53px'
              pos='relative'
              px='4'
              py='0'
            >
              <Box pos='relative' top='50%' transform='translateY(-50%)'>
                {cell.render('Cell')}
              </Box>
            </Td>
          ))}
        </Tr>
      )
    },
    areEqual
  )
  return (
    <Box mb='50px' w={{ base: '1000px', lg: 'full' }}>
      <Table
        {...getTableProps()}
        bgColor='white'
        border='none'
        borderWidth='3px'
        data-testid='incidentsPage_table'
        rounded={{ base: 'none', lg: 'lg' }}
        shadow='0px 2px 5px 6px rgba(0, 0, 0, 0.1)'
      >
        <Thead
          bgColor='#F2F4F9'
          data-testid='incidentsPage_table_header'
          overflowX='hidden'
          overflowY='auto'
        >
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps()}
                  alignItems='center'
                  borderColor='#D5DCE4'
                  color='#2D2E41'
                  d='flex'
                  fontSize='14px'
                  fontWeight='semibold'
                  isNumeric={column?.isNumeric}
                  key={column.id}
                  letterSpacing='-1px'
                  onClick={() => headerClicked(column)}
                  px='4'
                  textTransform='capitalize'
                >
                  <Box d='inline-block' {...column?.getSortByToggleProps()}>
                    {column.render('Header')}
                  </Box>
                  <chakra.span>
                    {column?.isSorted ? (
                      column?.isSortedDesc ? (
                        <ArrowDownIcon aria-label='sorted descending' />
                      ) : (
                        <ArrowUpIcon aria-label='sorted ascending' />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody ref={ref}>
          {isLoading ? (
            <Box
              as='tr'
              display='grid'
              height={data.length * ROW_HEIGHT}
              placeItems='center'
            >
              <Box as='td'>
                <Spinner
                  color='#298bbd'
                  emptyColor='gray.300'
                  size='md'
                  thickness='4px'
                />
              </Box>
            </Box>
          ) : (
            <FixedSizeList
              height={data.length * ROW_HEIGHT}
              itemCount={rows.length}
              itemData={itemData}
              itemSize={ROW_HEIGHT}
            >
              {RenderRow}
            </FixedSizeList>
          )}
        </Tbody>
        <Tfoot bgColor='#F2F4F9'>
          <Tr>
            <Td
              alignItems='center'
              color='#2D2E41'
              display='grid'
              fontSize='14px'
              fontWeight='semibold'
              gridTemplateColumns='repeat(3, 1fr)'
              letterSpacing='-1px'
              textTransform='none'
            >
              <Box as='p' data-testid='incidentsPage_table_currentRange'>
                {getCurrentRangeMessage(pageIndex, pageSize, totalIncidents)}
              </Box>
              <Navigation
                dataTestId='incidentsPage_table_navigation'
                gotoPage={gotoPage}
                nextPage={nextPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                previousPage={previousPage}
              />
              <PageSizeSelector
                dataTestId='incidentsPage_table_pageSize'
                onClick={(value) => dispatch(setIncidentsPageSize(value))}
                pageSize={pageSize}
                setPageSize={setPageSize}
                title='Incidents per page:'
              />
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </Box>
  )
}
