import React from 'react'

import { useCall } from '../hooks/useCall'
import { CgExpand } from 'react-icons/cg'

import { Box, Flex, Tooltip } from '@chakra-ui/react'

import { CallAvatar } from './CallAvatar'
import { CallHeader } from './CallHeader'

export const CallMinimized = () => {
  const { isMuted, callDuration, expandCall } = useCall()

  return (
    <Box alignItems='center' direction='row' display='flex' w='410px'>
      <CallAvatar fontSize='18px' size='60px' title='911' />
      <CallHeader
        duration={callDuration}
        isMuted={isMuted}
        subtitle='Live'
        title='Local Law Enforcement'
      />
      <Flex flex={1} justifyContent='flex-end'>
        <Tooltip label='Expand' placement='top'>
          <Box
            _hover={{
              opacity: 1.0,
            }}
            cursor='pointer'
            data-testid='CallMinimized:button'
            onClick={expandCall}
            opacity={0.3}
          >
            <CgExpand data-testid='CallMinimized:icon' size={24} />
          </Box>
        </Tooltip>
      </Flex>
    </Box>
  )
}
