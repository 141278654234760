import React from 'react'

import { Box, Text } from '@chakra-ui/react'

import { CallAvatar } from './CallAvatar'

export const CallEnded = () => {
  return (
    <Box
      alignItems='center'
      display='flex'
      flex={1}
      flexDirection='column'
      mt='50px'
      p='20px'
    >
      <CallAvatar
        bgColor='white'
        border='1px solid #e2e8f0'
        colour='#969696'
        fontSize='28px'
        size='100px'
        title='911'
      />
      <Text color='#282424' fontSize='22px' fontWeight='normal' mt='20px'>
        Call Ended
      </Text>
      <Box
        bgColor='#e2e8f0'
        direction='row'
        display='flex'
        h='1px'
        mb='20px'
        mt='20px'
        w='60%'
      />
    </Box>
  )
}
