import { useEffect } from 'react'

import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle, HiOutlineTrash } from 'react-icons/hi'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import * as EmailValidator from 'email-validator'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
} from '@/components/ui'
import {
  TechniciansDocument,
  useFacilityNamesQuery,
  useTechnicianQuery,
  useUpdateTechnicianMutation,
} from '@/graphql/generated/hooks'
import { DeviceType } from '@/graphql/generated/schemas'
import {
  getFacilityOptions,
  getTechnicianFacilityOptions,
} from '@/utils/forms/selectOptions'

import { RoleExtended } from '../types/types'
import { RoleIcon } from './UserRoles'

const IconWrapperStyled = styled(Box)`
  .icon {
    width: 30px;
  }
`
interface FormInputsIProps {
  firstName: string
  lastName: string
  email: string
  facilities: { label: string; value: string }[]
  deviceTypes: { label: string; value: DeviceType }[]
}

const deviceTypesOptions = [
  { label: 'Camera', value: DeviceType.Camera },
  { label: 'Door', value: DeviceType.Door },
]

interface IProps {
  isOpen: boolean
  onClose: () => void
  onRemoveOpen: () => void
  technicianId: string
}

export const EditTechnicianModal = ({
  isOpen,
  onClose,
  onRemoveOpen,
  technicianId,
}: IProps) => {
  const { shouldEnableUpdateTechnician } = usePermissions()

  const { handleSubmit, register, control, errors, setValue } =
    useForm<FormInputsIProps>({
      mode: 'onChange',
    })
  const { data: facilitiesData, loading: isFacilitiesLoading } =
    useFacilityNamesQuery({
      fetchPolicy: 'network-only',
    })
  const { data: technicianData, loading: isTechnicianLoading } =
    useTechnicianQuery({
      variables: {
        id: technicianId,
      },
      fetchPolicy: 'network-only',
    })
  const [updateTechnician, { loading: isUpdateTechnicianLoading }] =
    useUpdateTechnicianMutation()

  const facilityOptions = getFacilityOptions(facilitiesData)

  const { showSuccess, showError } = useToasts()

  const onSubmit: SubmitHandler<FormInputsIProps> = async (values) => {
    const data = {
      id: technicianId,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      facilityIds: values.facilities.map((f) => f.value),
      deviceTypes: values.deviceTypes.map((d) => d.value),
    }

    try {
      await updateTechnician({
        variables: {
          input: data,
        },
        refetchQueries: [
          {
            query: TechniciansDocument,
          },
        ],
      })
      onClose()
      showSuccess('Technician edits saved.')
    } catch {
      showError()
    }
  }

  useEffect(() => {
    if (technicianData && facilitiesData) {
      setValue('firstName', technicianData?.technician?.firstName, {
        shouldValidate: true,
      })
      setValue('lastName', technicianData?.technician?.lastName, {
        shouldValidate: true,
      })
      setValue('email', technicianData?.technician?.email, {
        shouldValidate: true,
      })
      setValue('facilities', getTechnicianFacilityOptions(technicianData))
      setValue(
        'deviceTypes',
        deviceTypesOptions.filter((option) =>
          technicianData?.technician?.deviceTypes.includes(option.value)
        )
      )
    }
  }, [isTechnicianLoading, isFacilitiesLoading])

  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader>
          <Box alignItems='center' d='flex'>
            <IconWrapperStyled>
              <RoleIcon role={RoleExtended.TECHNICIAN} />
            </IconWrapperStyled>
            <Box ml={3}>
              <Box
                color='#353849'
                fontSize='26px'
                fontWeight='extrabold'
                letterSpacing='-0.4px'
                lineHeight='1'
              >
                Edit Technician
              </Box>
            </Box>
          </Box>
        </ModalHeader>
        <ModalCloseButton color='#000' opacity={0.4} />
        <ModalBody p={0}>
          <Box px={6} py={2}>
            <form data-testid='EditTechnicianModal:form'>
              <Box mb={3}>
                <FormInputControl
                  data-testid='EditTechnicianModal:firstName'
                  errorMessage={errors.firstName && errors.firstName.message}
                  id='firstName'
                  inputRef={register({
                    required: 'Please add first name',
                  })}
                  isInvalid={!!errors.firstName}
                  label='Technician First Name'
                  placeholder='Enter Technician First Name'
                />
              </Box>
              <Box mb={3}>
                <FormInputControl
                  data-testid='EditTechnicianModal:lastName'
                  errorMessage={errors.lastName && errors.lastName.message}
                  id='lastName'
                  inputRef={register({
                    required: 'Please add last name',
                  })}
                  isInvalid={!!errors.lastName}
                  label='Technician Last Name'
                  placeholder='Enter Technician Last Name'
                />
              </Box>
              <Box mb={3}>
                <FormInputControl
                  data-testid='EditTechnicianModal:email'
                  errorMessage={errors.email && errors.email.message}
                  id='email'
                  inputRef={register({
                    required: 'You must specify an email',
                    validate: {
                      isEmail: (v) =>
                        EmailValidator.validate(v) || 'Invalid email address',
                    },
                  })}
                  isInvalid={!!errors.email}
                  label='Technician Email'
                  placeholder='Enter Technician Email'
                  type='email'
                />
              </Box>
              <Box mb={3}>
                <FormInputSelectControl
                  closeMenuOnSelect={false}
                  control={control}
                  data-testid='EditTechnicianModal:facilities'
                  defaultValue={null}
                  errorMessage='You must select at least one facility'
                  id='facilities'
                  isClearable
                  isInvalid={!!errors.facilities}
                  isMulti
                  isSearchable
                  label='Facilities'
                  options={facilityOptions}
                  placeholder='Select Facility(-ies)'
                  rules={{
                    required: true,
                    validate: (values) => values.length !== 0,
                  }}
                />
              </Box>
              <Box mb={3}>
                <FormInputSelectControl
                  closeMenuOnSelect={false}
                  control={control}
                  data-testid='EditTechnicianModal:deviceTypes'
                  defaultValue={null}
                  errorMessage='You must select at least one device type'
                  id='deviceTypes'
                  isClearable
                  isInvalid={!!errors.deviceTypes}
                  isMulti
                  isSearchable
                  label='Device Types'
                  options={deviceTypesOptions}
                  placeholder='Select Device Type(-s)'
                  rules={{
                    required: true,
                    validate: (values) => values.length !== 0,
                  }}
                />
              </Box>
            </form>
          </Box>
        </ModalBody>
        <ModalFooter py={4}>
          <Button
            data-testid='EditTechnicianModal:cancel'
            mr={3}
            onClick={onClose}
            variant='secondary'
          >
            Cancel
          </Button>
          <Button
            data-testid='EditTechnicianModal:remove'
            isDisabled={
              isTechnicianLoading ||
              !technicianData ||
              !shouldEnableUpdateTechnician
            }
            leftIcon={<HiOutlineTrash size='24px' />}
            loadingText='Removing technician'
            mr={3}
            onClick={() => {
              onClose()
              onRemoveOpen()
            }}
            variant='danger'
          >
            Remove technician
          </Button>
          <Button
            data-testid='EditTechnicianModal:submit'
            isDisabled={
              isTechnicianLoading ||
              !technicianData ||
              !shouldEnableUpdateTechnician
            }
            isLoading={isUpdateTechnicianLoading}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Saving changes...'
            onClick={handleSubmit(onSubmit)}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
