import { usePermissions } from '@/hooks/usePermissions'
import { HiExclamation } from 'react-icons/hi'

import { AlertDialogBody, AlertDialogFooter, Box, Flex } from '@chakra-ui/react'

import { BasicAlertDialog, Button } from '@/components/ui'

interface ConfirmEmergencyCallDialogIProps {
  isOpen: boolean
  onClose: () => void
  handleOnClick: () => void
}

export const ConfirmEmergencyCallDialog = ({
  isOpen,
  onClose,
  handleOnClick,
}: ConfirmEmergencyCallDialogIProps) => {
  const { shouldEnableIncidentEmergencyCall } = usePermissions()
  return (
    <BasicAlertDialog
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      title='Dialing 911'
    >
      <AlertDialogBody>
        <Box letterSpacing='-0.53px'>
          This will place an emergency call to the incident's local 911.
        </Box>
        <Flex alignItems='center' color='#D11D3A' flexDirection='row' pt='4'>
          <HiExclamation size='24px' />
          <Box
            fontSize='16px'
            fontWeight='medium'
            letterSpacing='-0.53px'
            ml='1'
          >
            <Box as='span' fontWeight='bold'>
              Warning:
            </Box>{' '}
            This will escalate the incident!
          </Box>
        </Flex>
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button mr='3' onClick={onClose} variant='secondary'>
          Cancel
        </Button>
        <Button
          isDisabled={!shouldEnableIncidentEmergencyCall}
          onClick={handleOnClick}
          variant='primary'
        >
          Call 911
        </Button>
      </AlertDialogFooter>
    </BasicAlertDialog>
  )
}
