import {
  CameraStreamStateIProps,
  IncidentPlayerError,
} from '@/features/video-player'

import {
  CANNED_CAMERA_FEED_PATH,
  CANNED_INCIDENT_VIDEO_PATH,
  salesDeviceIdVideoIdMap,
  salesIncidentIdVideoIdMap,
} from './data'

const getCannedCameraFeedPath = (deviceId: string): string | null =>
  deviceId in salesDeviceIdVideoIdMap
    ? `${CANNED_CAMERA_FEED_PATH}/${salesDeviceIdVideoIdMap?.[deviceId]}.mp4`
    : null

const getCannedIncidentVideoPath = (incidentId: string): string | null =>
  incidentId in salesIncidentIdVideoIdMap
    ? `${CANNED_INCIDENT_VIDEO_PATH}/${salesIncidentIdVideoIdMap[incidentId]}.mp4`
    : null

const getCannedCameraFeedError = (deviceId: string): string | null =>
  deviceId in salesDeviceIdVideoIdMap || deviceId === undefined
    ? null
    : IncidentPlayerError.NO_VIDEO_FEED

const getCannedIncidentVideoError = (incidentId: string): string | null =>
  incidentId in salesIncidentIdVideoIdMap || incidentId === undefined
    ? null
    : IncidentPlayerError.NO_INCIDENT_CLIP

export const getCannedCameraFeedStreamState = (
  deviceId: string
): CameraStreamStateIProps => ({
  url: getCannedCameraFeedPath(deviceId),
  isLoading: false,
  errorMessage: getCannedCameraFeedError(deviceId),
})

export const getCannedIncidentVideoStreamState = (
  incidentId: string
): CameraStreamStateIProps => {
  return {
    url: getCannedIncidentVideoPath(incidentId),
    isLoading: false,
    errorMessage: getCannedIncidentVideoError(incidentId),
  }
}
