import Image from 'next/image'

import { Box, Flex, Spinner } from '@chakra-ui/react'

const LOGO_ASPECT_RATIO = 406 / 364

// opt-out of image optimization, no-op
const customLoader = ({ src }) => {
  return src
}

export const PageLoader = () => {
  return (
    <Flex
      alignItems='center'
      bgColor='#E9ECF2'
      h='100vh'
      justifyContent='center'
      w='full'
    >
      <Flex alignItems='center' flexDir='column'>
        <Box mb='8'>
          <Image
            alt='HiveWatch Logo'
            height={230 / LOGO_ASPECT_RATIO}
            loader={customLoader}
            quality={4}
            src='/LogoLight.png'
            width={230}
          />
        </Box>
        <Spinner
          color='#3D7FCE'
          emptyColor='gray.300'
          size='xl'
          speed='0.65s'
          thickness='5px'
        />
      </Flex>
    </Flex>
  )
}
