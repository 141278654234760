interface FacilityWithFloorIdsI {
  defaultFloorId?: string
  floors?: { edges?: { node?: { id: string } }[] }
}

export const uuid = () =>
  (performance.now().toString(36) + Math.random().toString(36)).replace(
    /\./g,
    ''
  )
export const getMajorMinorVersion = (version: string): string => {
  const isMajorMinorPatchVersion = version?.split('.').length - 1 === 2
  if (isMajorMinorPatchVersion) {
    return version?.substring(0, version?.lastIndexOf('.'))
  }
  return version
}

export const getDefaultOrFirstFloorId = (
  facility: FacilityWithFloorIdsI
): string => facility?.defaultFloorId || facility.floors.edges[0]?.node?.id

// Ensure that all cases where handled in switch-case
// Reference: <https://www.meticulous.ai/blog/safer-exhaustive-switch-statements-in-typescript>
export const exhaustiveCheck = (switchArg: never) => {
  const exhaustiveCheck: never = switchArg
  throw new Error(`Unexpected type: ${exhaustiveCheck}`)
}
