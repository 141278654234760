export const EmergencyCall = ({
  className,
  strokeColor = '#fff',
}: {
  className?: string
  strokeColor?: string
}) => (
  <svg
    className={className}
    fill='none'
    height={47}
    viewBox='0 0 50 47'
    width={50}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.084 12.533c.486 2.359-.573 3.068-.573 3.068l-3.09 1.863c-1.234.702-.557 2.16-.557 2.16 4.825 10.036 10.16 13.059 12.102 14.402 1.41.97 2.261.283 2.261.283l3.981-3.183c1.41-1.328 2.492-.412 2.492-.412l7.843 5.625c1.338.9.733 2.717-.23 4.29-.956 1.549-4.921 5.25-9.85 4.396-4.928-.848-13.694-4.213-19.69-12.823-6.05-8.709-8.16-16.326-8.16-16.326C.048 7.961 6.624 3.741 6.624 3.741c5.175-3.13 7.596.206 7.596.206s1.377 6.228 1.863 8.586Z'
      stroke={strokeColor}
      strokeLinejoin='round'
      strokeWidth={3}
    />
    <path
      d='M33.356 1.941a1.951 1.951 0 0 0-1.95 1.951v6.09h-7.699a1.951 1.951 0 0 0-1.951 1.95v2.927c0 1.078.873 1.951 1.951 1.951h7.698v7.547c0 1.078.874 1.951 1.951 1.951h2.927a1.951 1.951 0 0 0 1.95-1.95V16.81h7.012a1.951 1.951 0 0 0 1.95-1.95v-2.927a1.951 1.951 0 0 0-1.95-1.951h-7.011v-6.09a1.951 1.951 0 0 0-1.951-1.95h-2.927Z'
      stroke={strokeColor}
      strokeLinejoin='round'
      strokeWidth={3}
    />
  </svg>
)
