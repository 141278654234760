import { Control } from 'react-hook-form'

import {
  Box,
  Collapse,
  Flex,
  FormControlOptions,
  FormLabel,
} from '@chakra-ui/react'

import { FormInputSelectControl } from '@/components/ui'
import { OptionI } from '@/utils/forms/selectOptions'

import { RadioButton } from '../RadioButton'

export interface FormRadioInputControlIProps extends FormControlOptions {
  control: Control
  id: string
  isLoading?: boolean
  toggleAll: boolean
  leftRadioButtonLabel: string
  rightRadioButtonLabel: string
  label?: string
  placeholder?: string
  dropdownContainerHeight?: string
  options: OptionI[]
  setToggleAll: (value: boolean) => void
  [key: string]: unknown
}

export const FormRadioInputControl = ({
  control,
  leftRadioButtonLabel,
  rightRadioButtonLabel,
  label,
  dropdownContainerHeight,
  id,
  isLoading,
  options,
  placeholder,
  toggleAll,
  setToggleAll,
  ...rest
}: FormRadioInputControlIProps) => {
  return (
    <Flex direction='column' flex={1}>
      <FormLabel htmlFor={id} mb={0}>
        {label}
      </FormLabel>
      <Flex direction='row' py='15px'>
        <Flex flex={1}>
          <RadioButton
            disabled={false}
            isActive={toggleAll}
            label={leftRadioButtonLabel}
            onClick={() => setToggleAll(true)}
          />
        </Flex>
        <Flex flex={1}>
          <RadioButton
            disabled={false}
            isActive={!toggleAll}
            label={rightRadioButtonLabel}
            onClick={() => setToggleAll(false)}
          />
        </Flex>
      </Flex>
      <Collapse in={!toggleAll}>
        <Box mb={3}>
          <FormInputSelectControl
            closeMenuOnSelect={false}
            control={control}
            defaultValue={null}
            dropdownContainerHeight={dropdownContainerHeight}
            id={id}
            isClearable
            isDisabled={false}
            isLoading={isLoading}
            isMulti
            isSearchable
            options={options}
            placeholder={placeholder}
            {...rest}
          />
        </Box>
      </Collapse>
    </Flex>
  )
}
