import { Control, Controller } from 'react-hook-form'
import { Validate } from 'react-hook-form/dist/types'
import { HiOutlineInformationCircle } from 'react-icons/hi'

import {
  FormControl as ChakraFormControl,
  FormControlOptions,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Tooltip,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

import { InputSelect } from '../InputSelect'

export const StyledFormInputSelectControl = styled(ChakraFormControl)`
  .chakra-form__label {
    opacity: ${(p) => (p.labelOpacity ? p.labelOpacity : 0.5)};
    color: ${(p) => (p.labelColour ? p.labelColour : '#2d2e41')};
    font-size: 16px;
    letter-spacing: -0.8px;
  }
`

export interface FormInputSelectControlIProps extends FormControlOptions {
  dataTestId?: string
  label?: string
  labelColour?: string
  labelOpacity?: number
  id?: string
  valid?: boolean
  errorMessage?: string
  width?: string
  isInvalid?: boolean
  placeholder?: string
  options?:
    | {
        value: string | unknown
        label: string | unknown
      }[]
    | {
        label: string
        options: {
          value: string
          label: string
        }[]
      }[]
  control?: Control
  rules?: {
    required?: boolean
    validate?: Validate
  }
  tooltipText?: string
  [key: string]: unknown
}

export const FormInputSelectControl = ({
  id,
  label,
  labelColour,
  labelOpacity,
  isInvalid = false,
  valid = false,
  errorMessage,
  options,
  control,
  width = '100%',
  placeholder,
  rules,
  dataTestId,
  tooltipText,
  ...rest
}: FormInputSelectControlIProps) => {
  return (
    <StyledFormInputSelectControl
      data-testid={dataTestId}
      id={id}
      isInvalid={isInvalid}
      labelColour={labelColour}
      labelOpacity={labelOpacity}
      width={width}
    >
      {label && (
        <HStack alignItems='flex-start'>
          <FormLabel htmlFor={id} mr='0'>
            {label}
          </FormLabel>
          {tooltipText && (
            <Tooltip label={tooltipText} placement='right' shouldWrapChildren>
              <Icon
                as={HiOutlineInformationCircle}
                boxSize='16px'
                color='#33344B'
              />
            </Tooltip>
          )}
        </HStack>
      )}
      <Controller
        as={InputSelect}
        control={control}
        data-testid={dataTestId}
        isInvalid={isInvalid}
        name={id}
        options={options}
        placeholder={placeholder}
        rules={rules}
        valid={valid}
        {...rest}
      />
      <FormErrorMessage data-testid='form-control-select-error'>
        {errorMessage}
      </FormErrorMessage>
    </StyledFormInputSelectControl>
  )
}
