import { useEffect, useRef, useState } from 'react'

import { FeatureFlag, useFeatureFlag } from '@/hooks/useFeatureFlags'

import { NetworkStatus } from '@apollo/client'

import { getCannedIncidentVideoStreamState } from '@/features/canned-video'
import { useIncidentVideoQuery } from '@/graphql/generated/hooks'
import { IncidentVideoQuery } from '@/graphql/generated/operations'
import { IncidentType } from '@/graphql/generated/schemas'

import { VideoFeedEvent } from '../types/types'
import { IncidentPlayerError, IncidentVideoStateIProps } from '../types/types'

const incidentTypesWithVideo = [
  IncidentType.ForcedEntry,
  IncidentType.DoorHeldOpen,
  IncidentType.DoorHeldClose,
  IncidentType.Tailgating,
  IncidentType.LineCrossing,
]
const VIDEO_PROCESSING_POLL_INTERVAL = 3000

interface UseIncidentVideoIProps {
  incidentId: string
  incidentType: IncidentType
  hasCamera: boolean
  onEvent?: (event: VideoFeedEvent) => void
  skip?: boolean
}

export const useIncidentVideo = ({
  incidentId,
  incidentType,
  hasCamera,
  onEvent,
  skip,
}: UseIncidentVideoIProps): IncidentVideoStateIProps => {
  const timeoutRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [url, setUrl] = useState(null)

  const isCannedVideoEnabled = useFeatureFlag(FeatureFlag.cannedVideo)

  const handleCompleted = (data: IncidentVideoQuery) => {
    setIsLoading(false)
    if (data?.incident?.video) {
      onEvent?.(VideoFeedEvent.Received)
      const { url, expiryTime } = data?.incident?.video
      if (url && expiryTime) {
        const expiresTimestamp = Date.parse(expiryTime)
        const nowTimestamp = Date.now()
        timeoutRef.current = setTimeout(
          refetch,
          expiresTimestamp - nowTimestamp
        )
        setIsProcessing(false)
        setError(null)
        setUrl(url)
        stopPolling()
      }
    } else if (hasCamera && incidentTypesWithVideo.includes(incidentType)) {
      startPolling(VIDEO_PROCESSING_POLL_INTERVAL)
      setIsProcessing(true)
    } else {
      setUrl(null)
      setError(IncidentPlayerError.NO_INCIDENT_CLIP)
    }
  }

  const handleError = () => {
    setIsLoading(false)
    setUrl(null)
    setError(IncidentPlayerError.ERROR_FETCHING_INCIDENT_CLIP)
  }

  const { refetch, networkStatus, startPolling, stopPolling } =
    useIncidentVideoQuery({
      variables: { id: incidentId },
      fetchPolicy: 'no-cache',
      skip: !incidentId || isCannedVideoEnabled || skip,
      onError: handleError,
      onCompleted: handleCompleted,
    })

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  useEffect(() => {
    if (networkStatus == NetworkStatus.setVariables) {
      setIsLoading(true)
      onEvent?.(VideoFeedEvent.BeginRequesting)
    }
  }, [networkStatus])

  return isCannedVideoEnabled
    ? getCannedIncidentVideoStreamState(incidentId)
    : { url, isLoading, errorMessage: error, isProcessing }
}
